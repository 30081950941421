import React from "react"

import Fotos from "@components/pageAgile/image/fotosgrupo.png"
import Image from "@components/pageAgile/image/image.png"
import ImageMobile from "@components/pageAgile/image/imageMobile.png"
import CardOne from "@components/pageAgile/image/cardOne.png"
import CardTwo from "@components/pageAgile/image/cardTwo.png"
import CardThree from "@components/pageAgile/image/cardThree.png"
import cardModules from "@components/pageAgile/image/modulesCard/bannercard.png"
import cardModulesOne from "@components/pageAgile/image/modulesCard/cardOne.png"
import cardModulesTwo from "@components/pageAgile/image/modulesCard/cardTwo.png"
import cardModulesThree from "@components/pageAgile/image/modulesCard/cardThree.png"
import card1 from "@components/pageAgile/image/modulesCard/card1.png"
import card2 from "@components/pageAgile/image/modulesCard/card2.png"
import card3 from "@components/pageAgile/image/modulesCard/card3.png"
import card4 from "@components/pageAgile/image/modulesCard/card4.png"
import card5 from "@components/pageAgile/image/modulesCard/card5.png"
import circleMethodology from "@components/pageAgile/image/circleMethodology.png"

//silder card
import silderOne from "@components/pageAgile/image/silderCards/fotoOne.png"
import silderTwo from "@components/pageAgile/image/silderCards/fotoTwo.png"
import silderThree from "@components/pageAgile/image/silderCards/fotoThree.png"
import silderFour from "@components/pageAgile/image/silderCards/fotoFour.png"

//iconos de modulos
import TitleUnderlineStart from "@components/pageAgile/components/titleUnderlineStart"
import TitleUnderlineBecause from "@components/pageAgile/components/titleUnderlineBecause"
import TitleUnderlineMetodology from "@components/pageAgile/components/titleUnderlineMetodology"

//image sentecia
import SentenceImageDesktop from "@components/pageAgile/image/sentenceDesktop.png"
import SentenceImageMobile from "@components/pageAgile/image/sentenceMobile.png"

const title = (
  <h1 className="text-title-agile">
    <TitleUnderlineStart underline="Ágilmente" /> innovando el mercado
  </h1>
)
const titleDescription = (
  <p className="title-becausewe">
    ¡Aquí <TitleUnderlineBecause underline=" parte todo" />!
  </p>
)

const textstart = (
  <p>
    Para nosotros, la agilidad no solo es una metodología de moda.{" "}
    <b>
      {" "}
      Es una filosofía de trabajo, un combustible que nos impulsa a superarnos a
      nosotros
    </b>{" "}
    mismos para alcanzar metas más ambiciosas y ofrecer soluciones innovadoras.
    Aquí te contaremos los componentes que forman parte de nuestra fórmula
    secreta.
  </p>
)

const textbodyDescription = (
  <p>
    - Cuando tenemos en claro a dónde apuntamos, identificamos los resultados
    claves necesarios para alcanzar cada objetivo, y asignamos responsabilidades
    a cada equipo.
    <br />
    <br />
    - Además, damos seguimientos constantes al progreso de cada equipo,
    mantenemos reuniones regulares y actualizaciones permanentes de nuestro
    estado actual.
    <br />
    <br />- También podemos ajustar nuestros objetivos y resultados claves
    durante el proceso de trabajo. Todo esto nos mantiene motivados y más
    comprometidos.
  </p>
)

const data = {
  intro: {
    title: title,
    textbody: textstart,
    image: Fotos,
  },
  description: {
    title: titleDescription,
    text:
      "La génesis de nuestro trabajo parte de los objetivos trazados bajo la metodología OKR. Establecemos metas estratégicas con el fin de lograr un crecimiento sostenible dentro del mercado.",
    span:
      "Nuestros objetivos OKR deben cumplir con las siguientes características:",
    textbody: textbodyDescription,
    image: Image,
    cardOne: CardOne,
    cardTwo: CardTwo,
    cardThree: CardThree,
    imageMobile: ImageMobile,
  },
  modules: {
    title: "Transformando a los equipos con Scrum",
    text: (
      <>
        La metodología Scrum está como eje principal en el funcionamiento de
        nuestros equipos de desarrollo para entregar trabajos de alto valor con
        nuevos features y mejoras en nuestros productos{" "}
        <b> que nos mantienen competitivos dentro del mercado.</b>
        <br />
        <br />
        Aquí te contamos brevemente cómo es nuestro proceso de trabajo:
      </>
    ),
    image: cardModules,
    cardbanner: [card1, card3, card4, card2, card5],
    textsupport: (
      <>
        <b>
          Con Scrum podemos cumplir con los plazos y entregar un trabajo de
          calidad.
        </b>{" "}
        Además, ayuda a los equipos a mejorar y aprender continuamente, lo que
        nos permite seguir creciendo en conjunto.
      </>
    ),
    cardImages: [cardModulesOne, cardModulesTwo, cardModulesThree],
  },
  methodology: {
    title: (
      <>
        Creamos nuestra <TitleUnderlineMetodology underline="propia metodología" />
      </>
    ),
    image: circleMethodology,
    text: (
      <>
        ¡Nos gusta innovar en todo! Por eso,{" "}
        <b>
          {" "}
          hemos creado nuestra propia metodología Beex Operations Agile
          (SOA)
        </b>
        , inspirada en los principios básicos de la agilidad. Aquí buscamos
        adaptar nuestra atención al cliente de forma ágil y objetiva.
        <br />
        <br />{" "}
        <b>
          La atención al cliente de calidad forma parte de nuestro valor
          agregado.
        </b>{" "}
        Por eso, trabajamos diariamente ser los mejores. Para lograrlo,
        planteamos el siguiente proceso.
        <br />
        <br />
        Nuestra metodología SOA crea un enfoque ágil en la atención al cliente
        que nos permite{" "}
        <b>
          {" "}
          mantener la satisfacción de nuestros clientes en el más alto nivel.
        </b>
      </>
    ),
  },
  sildercard: [
    {
      title: "Agilidad",
      text:
        "Cada miembro de nuestro equipo trabaja bajo el mismo norte de la agilidad para impactar con responsabilidad cada una de las metas trazadas.",
      image: silderOne,
      color: "#F0F5FF",
    },
    {
      title: "Compromiso",
      text:
        "Utilizamos herramientas de agilidad que nos aseguran cumplir con los objetivos planteados dentro de los plazos establecidos.",
      image: silderTwo,
      color: "#FEFBE8",
    },
    {
      title: "Conocimiento",
      text:
        "Nos comprometemos con el aprendizaje continuo para mejorar nuestra eficiencia y eficacia en cada trabajo que realizamos.",
      image: silderThree,
      color: "#ECF9FA",
    },
    {
      title: "Innovación",
      text:
        "La agilidad no es solo un modo de trabajo, también es nuestra cultura que nos ayuda a ser más creativos e innovadores.",
      image: silderFour,
      color: "#FAF3E9",
    },
  ],
  sentence: {
    text: "“La única forma de hacer un gran trabajo, es amar lo que haces”",
    autor: "Steve Jobs",
    frase: "Frase que nos identifica",
    imageDesktop: SentenceImageDesktop,
    imageMobile: SentenceImageMobile,
  },
}

export default data
