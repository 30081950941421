import React from "react"

const Sentence = ({ data }) => {
  return (
    <div className="container-sentence-frase-agile">
      <img
        className="sentence-frase-agile-imageDesktop"
        src={data.imageDesktop}
        alt=""
        loading="lazy"
      />
      <img
        className="sentence-frase-agile-imageMobile"
        src={data.imageMobile}
        alt=""
        loading="lazy"
      />
      <div className="sentence-frase-agile-section">
        <svg
          width="51"
          height="38"
          viewBox="0 0 51 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1573_62)">
            <path
              d="M12.5812 38.0021C5.18819 38.0021 0 32.8405 0 24.9551C0 10.5027 8.58872 3.04468 16.8636 0.248377C19.6683 -0.700627 22.6437 1.22274 22.6437 3.94659C22.6437 5.55845 21.5592 7.00007 19.9185 7.60135C14.6747 9.52109 10.4916 14.3784 10.2215 19.6522C10.5353 19.5074 11.9495 18.9351 13.5227 18.9351C19.6563 18.9351 24.058 22.8071 24.058 28.541C24.058 33.7026 19.1836 38.0021 12.5812 38.0021Z"
              fill="#4894CD"
            />
            <path
              d="M39.5193 37.9999C32.2852 37.9999 26.9381 32.8384 26.9381 24.9529C26.9381 10.5404 35.5824 3.0824 43.7938 0.267983C46.5944 -0.691887 49.5818 1.2351 49.5818 3.9662C49.5818 5.57081 48.5092 6.99794 46.8805 7.61008C41.7519 9.53707 37.5846 14.3835 37.3145 19.6501C37.6283 19.5052 38.8876 18.9329 40.4608 18.9329C46.7494 18.9329 50.9961 22.805 50.9961 28.5389C51 33.7004 46.1257 37.9999 39.5193 37.9999Z"
              fill="#4894CD"
            />
          </g>
          <defs>
            <clipPath id="clip0_1573_62">
              <rect width="51" height="38" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p className="sentence-frase-agile-section-text">{data.text}</p>
        <p className="sentence-frase-agile-section-autor"><b>{data.autor}</b></p>
        <p  className="sentence-frase-agile-section-frase">{data.frase}</p>
      </div>
    </div>
  )
}

export default Sentence
