import React from "react"

const AboutUs = ({ data }) => {
  return (
    <div className="section-ourMethodology-agile">
      <p className="title-ourMethodology-agile">{data.title}</p>
      <div className="section-ourMethodology-agile-container">
        <img className="image-ourMethodology-agile" src={data.image} alt="comillas" loading="lazy" />
        <p className="text-ourMethodology-agile">{data.text}</p>
      </div>
    </div>
  )
}

export default AboutUs
