import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import useNearScreen from "@hooks/useNearScreen"

const ModulesAgile = ({ data }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    slidesToShow: 1,
    infinite: true,
    initialSlide: 0,
    dots: false,
    speed: 500,
  }

  return (
    <section className="container-module-transfor-agile">
      <p className="title-module-transfor-agile">{data.title}</p>
      <p className="text-module-transfor-agile">{data.text}</p>
      <div className="container-image-module-transfor-agile">
        <img src={data.image} alt="" className="image-module-transfor-agile" />
        <div className="container-cards-image-module-transfor-agile">
        {data.cardbanner.map((ele, i) => {
          return <img src={ele} alt="" key={i} className={`cards-image-module-transfor-agile card-module-agile-${i}`} />
        })}
        </div>
      </div>

      <div className="silder-module-transfor-agile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.cardImages.map((elemento, i) => {
            return (
              <img
                key={i}
                className="img-channels"
                src={elemento}
                alt="canales"
              />
            )
          })}
        </Slider>
      </div>
      <p className="text-support-module-transfor-agile">{data.textsupport}</p>
    </section>
  )
}
export default ModulesAgile
