import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import TitleUnderline from "./titleUnderlineSilder"

import useNearScreen from "@hooks/useNearScreen"
const AboutUs = ({ data }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    slidesToShow: 4,
    infinite: true,
    initialSlide: 0,
    dots: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
        },
      },
    ],
  }

  return (
    <div className="container-silder-card-agile" ref={fromRef}>
      <p className="title-ourMethodology-agile">
        ¡Lo que nunca <TitleUnderline underline=" nos faltará!" />
      </p>
      <div className="section-silder-card-agile-desktop">
        {data.map((elemento, i) => {
          return (
            <div key={i} style={{ background: elemento.color }}
                className="section-silder-card-agile-cards"
              >
                <img
                  className="section-silder-card-agile-image"
                  src={elemento.image}
                  alt=""
                />
                <p className="section-silder-card-agile-title">
                  <b> {elemento.title}</b>
                </p>
                <p className="section-silder-card-agile-text">
                  {elemento.text}
                </p>
              </div>
          )
        })}
      </div>

      <div className="section-silder-card-agile-mobile">
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <div key={i}>
                <div
                  style={{ background: elemento.color }}
                  className="section-silder-card-agile-cards"
                >
                  <img
                    className="section-silder-card-agile-image"
                    src={elemento.image}
                    alt=""
                  />
                  <p className="section-silder-card-agile-title">
                    <b> {elemento.title}</b>
                  </p>
                  <p className="section-silder-card-agile-text">
                    {elemento.text}
                  </p>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default AboutUs
