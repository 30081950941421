import React from "react"
const Start = ({ data }) => {
  return (
    <div className="container-section-image-text-agile">
      {data.title}
      <p className="text-becausewe-agile">{data.text}</p>
      <p className="text-becausewe-agile-span">{data.span}</p>
      <section className="section-image-text-agile">
        <div className="section-image-text-agile-image">
          <img
            className="img-because-agile-cardOne"
            src={data.cardOne}
            alt="img-because"
            loading="lazy"
          />
          <img
            className="img-because-agile"
            src={data.image}
            alt="img-because"
            loading="lazy"
          />
          <img
            className="img-because-agile-cardTwo"
            src={data.cardTwo}
            alt="img-because"
            loading="lazy"
          />
          <img
            className="img-because-agile-cardThree"
            src={data.cardThree}
            alt="img-because"
            loading="lazy"
          />
        </div>

        <section className="description-becausewe-agile">
          {data.textbody}
        </section>
        <div className="section-img-because-agile-mobile">
          <p>
            <b>{data.span}</b>
          </p>
          <img
            className="img-because-agile-mobile"
            src={data.imageMobile}
            alt="img-because"
            loading="lazy"
          />
        </div>
      </section>
    </div>
  )
}

export default Start
